import { createSlice } from '@reduxjs/toolkit';
import {
  getBannersPromotion,
  getProductBrandPromotion,
} from '~store/campaign/thunks';

const { reducer } = createSlice({
  name: 'campaign',
  initialState: {
    campaign: '',
    campaignBanners: [],
    brandProducts: [],
    tabKeyActive: '',
    status: {
      campaignBanners: 'idle',
      brandProducts: 'idle',
    },
  },
  reducers: {
    setState(state, { value, name }) {
      state[name] = value;
    },
    setStatusApi(state, { value, name }) {
      state.status[name] = value;
    },
    resetState(state) {
      state.campaignBanners = [];
      state.brandProducts = [];
      state.tabKeyActive = '';
      state.status = {
        campaignBanners: 'idle',
        brandProducts: 'idle',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBannersPromotion.pending, (state, action) => {
        state.status.campaignBanners = 'loading';
      })
      .addCase(getBannersPromotion.rejected, (state, action) => {
        state.status.campaignBanners = 'error';
      })
      .addCase(getBannersPromotion.fulfilled, (state, { payload }) => {
        state.status.campaignBanners = 'finished';
        state.campaignBanners = payload?.section?.data?.sections || [];
        state.campaign = payload?.campaign?.data || '';
      })
      .addCase(getProductBrandPromotion.pending, (state, action) => {
        state.status.brandProducts = 'loading';
      })
      .addCase(getProductBrandPromotion.rejected, (state, action) => {
        state.status.brandProducts = 'error';
      })
      .addCase(getProductBrandPromotion.fulfilled, (state, { payload }) => {
        state.status.brandProducts = 'finished';
        state.brandProducts = payload.data;
      });
  },
});

export default reducer;
