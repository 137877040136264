import { createSlice } from '@reduxjs/toolkit';
import { getBannersAll } from '~store/banners/thunks';
//
export const { actions, reducer } = createSlice({
  name: 'banners',
  initialState: {
    locations: {},
    status: {
      locations: 'idle',
    },
  },
  reducers: {
    setState: (state, { payload: [key, value] }) => {
      state[key] = value;
    },
    setStatus: (state, { payload: [key, value] }) => {
      state.status[key] = value;
    },
  },
  extraReducers: {
    [getBannersAll.pending]: (state) => {
      state.status.locations = 'pending';
    },
    [getBannersAll.fulfilled]: (state, { payload }) => {
      state.locations = payload || {};
      state.status.locations = 'succeeded';
    },
  },
});

export default reducer;
