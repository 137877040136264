import { createAsyncThunk } from '@reduxjs/toolkit';
import { Catalog } from '~api/catalog';

export const getBannersPromotion = createAsyncThunk(
  'campaign/getBannersPromotion',
  async (valueType) => {
    try {
      const newDataSections = [];
      let keyTabBanner;
      let id = valueType;
      let params = {};
      if (valueType?.valueType) {
        id = valueType.valueType;
        params = valueType.params;
      }
      const response = await Catalog.getBannersPromotion(id, params);
      if (response?.data?.data?.sections?.length) {
        response.data.data.sections.map((section, key) => {
          const isSectionHasDataBanner =
            section.banner &&
            Array.isArray(section.banner) &&
            section.banner.length;
          if (isSectionHasDataBanner || section.is_child) {
            const bannerType =
              section.banner && section.banner.length
                ? section.banner[0].banner_type
                : '';
            if (bannerType === 'tab_banner') {
              keyTabBanner = key;
            } else if (bannerType !== 'tab_banner' && section.is_child) {
              if (keyTabBanner) {
                if (!response.data.data.sections[keyTabBanner].listTabKey) {
                  response.data.data.sections[keyTabBanner].listTabKey = {};
                }

                if (
                  !response.data.data.sections[keyTabBanner].listTabKey[
                    section.tab_key?.toString()
                  ]
                ) {
                  response.data.data.sections[keyTabBanner].listTabKey[
                    section.tab_key?.toString()
                  ] = [];
                }

                response.data.data.sections[keyTabBanner].listTabKey[
                  section.tab_key?.toString()
                ].push(section);
              }
            } else {
              newDataSections.push(section);
            }
          } else {
            newDataSections.push(section);
          }
        });
        if (keyTabBanner) {
          newDataSections.splice(
            keyTabBanner,
            0,
            response.data.data.sections[keyTabBanner],
          );
        }
        // console.log('newDataSections', newDataSections)
        // console.log('response.data.data.sections', response.data.data.sections)
        response.data.data.sections = newDataSections;
        return { section: response.data, campaign: response.data };
      } else {
        return { section: [], campaign: '' };
      }
    } catch (err) {
      console.log(err);
    }
  },
);

export const getProductBrandPromotion = createAsyncThunk(
  'campaign/getProductBrandPromotion',
  async ({ id, section, params }) => {
    try {
      const response = await Catalog.getProductBrandPromotion(
        { id, section },
        params,
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
);
