import { createAsyncThunk } from '@reduxjs/toolkit';
import { Catalog } from '~api/catalog';

export const getBannersCampaign = createAsyncThunk(
  'campaignV2/getBannersCampaign',
  async (valueType) => {
    try {
      const newDataSections = [];
      let id = valueType;
      let params = {};
      if (valueType?.valueType) {
        id = valueType.valueType;
        params = valueType.params;
      }
      const response = await Catalog.getBannersCampaigns(id, params);
      if (response?.data?.data?.sections?.length) {
        response.data.data.sections.map((section, key) => {
          if (section.banner_type === 'tab') {
            response.data.data.sections[key].listTabKey =
              response.data.data.childs;
          }

          newDataSections.push(section);
        });

        response.data.data.sections = newDataSections;
        return { section: response.data, campaign: response.data };
      } else {
        return { section: [], campaign: '' };
      }
    } catch (err) {
      console.log(err);
    }
  },
);
